@import '~bootswatch/dist/darkly/bootstrap.min';

@media (prefers-color-scheme: light) {
  @import '~bootswatch/dist/flatly/bootstrap.min';
}

body {
  margin: 0;
  font-family: 'Fira code', 'Fira Mono', source-code-pro, Menlo, Monaco,
    Consolas, 'Courier New', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// From https://stackoverflow.com/a/51775832/1063392
.flex-even {
  flex: 1;
}

.no-resize {
  resize: none;
}

.no-wrap {
  white-space: nowrap;
}

.offline {
  .hide-offline {
    display: none;
  }
}

.online {
  .hide-online {
    display: none;
  }
}
